.headerContainer{
    padding: 2%;
    position: relative;
}

.Avatar{
    text-align: left;
}

.StreamerName{
    text-align: left;
    margin-left: 7%;
}
 
#enterRoomBtn{
    display:flex;
    margin-left:  7%;
    margin-right:  80%;
}

.introDiv{
    text-align: left;
    margin-left:7%;
}

.avatarDiv{
    flex:2
}

#biliSpaceBtn{
    background-color: rgba(79, 158, 228, 0.185);
    color: rgb(79, 158, 228);
}
#biliSpaceBtn:hover{
    opacity:0.5;
    background-color:rgba(240,248,255,0.5);
    cursor:pointer;
}

