#searchBar {
    margin-top: 3%;
    margin-bottom: 3%;
}

#searchInput{
    color: aliceblue;
    position: relative;
}

/*table title content color*/
.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium{
    color: rgb(240, 255, 255);
    border: none;
}
 
/*table Row content color*/
.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium{
    color: rgb(240, 255, 255);
    border: none;
}

/*pagination setting*/
.MuiTablePagination-selectLabel{
    color: rgb(240, 255, 255);
    border: none;
}

.MuiInputBase-root.MuiInputBase-colorPrimary{
    color: rgb(240, 255, 255);
    border: none;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTablePagination-selectIcon.MuiSelect-icon.MuiSelect-iconStandard{
    color: rgb(240, 255, 255);
}

.MuiTablePagination-displayedRows{
    color: rgb(240, 255, 255);
}
.MuiButtonBase-root.Mui-disabled.MuiIconButton-root.Mui-disabled.MuiIconButton-sizeMedium{
    color: rgb(240, 255, 255);
}
.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium{
    color: rgb(240, 255, 255);
}
/*pagination setting end*/
