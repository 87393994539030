#bgVideo {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -100;
  }
/* main container setting */
.MuiContainer-root.MuiContainer-maxWidthLg.container{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(68, 63, 63, 0.678);
    z-index: 8;
}
/* footer setting */
.MuiButtonBase-root.MuiFab-root.MuiFab-circular.MuiFab-sizeSmall.MuiFab-default{
    color: black;
} 

/*scroll to top btn setting*/
.MuiBox-root{
    z-index: 9;
}